import * as React from "react";
import { useEffect } from "react";
import "./App.css";
import { Index } from "./pages/main/Index";
import { useLocation } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

///dialogue box //

import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";

// import SignIn from './pages/signIn/SignIn';
// import SignUp from './pages/signUp/SignUp';

function App() {
  const location = useLocation();
  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    window.AOS.init({
      duration: 1000,
      offset: 200,
    });
    setOpen(true);
  }, [location]);

  ///dialogue box work//

  return (
    <div className="">
      <Dialog
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            className="text-center"
          >
            <h3>
              This domain is <span style={{ color: "#FDB515" }}>available</span>{" "}
              for sale for just $6999.
            </h3>
            For more details please contact us at{" "}
            <span style={{ color: "#FDB515" }}>(760) 284-6958</span>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default App;
